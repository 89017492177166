import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, of } from "rxjs";
import { Alert } from "../../../types/alert";
import { AuthenticationService } from "src/app/services/authentication.service";
import { BaseBeneficiario } from "../../../types/BaseBeneficiario";
import { Beneficiario } from "src/app/types/Beneficiario";
import { BeneficiarioService } from "src/app/services/beneficiario.service";
import { DadosBeneficiario } from "./../../../types/DadosBeneficiarios";
import { Dependentes } from "./../../../types/Dependentes";
import { EventEmitterService } from "../../../services/event-emitter.service";
import { MatDialog } from "@angular/material/dialog";
import { MessageService } from "src/app/services/message.service";
import { TermoAceiteService } from "./../../../services/termo-aceite.service";

@Component({
  selector: "app-detalhes",
  templateUrl: "./page-detalhes.component.html",
  styleUrls: ["./page-detalhes.component.scss"],
  host: { class: "full-width" },
})
export class PageDetalhesComponent
  extends BaseBeneficiario
  implements OnInit, OnDestroy
{
  pep: string;
  alert = Alert;
  userHandle: string;
  beneficiarios$: Observable<DadosBeneficiario>;
  pageClass = "page-detalhes";
  beneficiarioCompleto: boolean;
  autorizacao: string;
  dependenteColor = "";
  BeneficiarioSelecionado: Observable<DadosBeneficiario>;
  usuario: Beneficiario;
  dependentes: Dependentes[];
  pageLength: number;
  pageIndex: number;
  paginatorStatus: {
    pageIndex: number;
    pageSize: number;
  };
  base64: string;
  visualizarModalDetalhes: boolean = false;
  userHandleSelecionado: string;
  dependenteSelecionado: Observable<DadosBeneficiario[]>;
  consultaDependente: boolean;
  autenticador: any;

  constructor(
    private beneficiarioService: BeneficiarioService,
    public auth: AuthenticationService,
    private router: Router,
    private messageService: MessageService,
    protected route: ActivatedRoute,
    protected dialog: MatDialog,
    private termoAceite: TermoAceiteService
  ) {
    super(route, auth, dialog);
    this.beneficiarios$ = of();
    this.consultaDependente = false;

    try {
      this.auth.currentUser$.subscribe((x) => {
        this.pep = x.situacaoPep === true ? Alert.SUCCESS : Alert.WARNING;
      });
      if (this.auth.currentUserValue.tipoUsuarioId === 1) {
        this.router.navigate([`/detalhes`]).then(() => {});
      }
    } catch (error) {
      console.error(
        "Encontramos um erro ao tentar atender sua solicitação. Por favor tente novamente mais tarde. \nSe o problema persistir entre em contato conosco."
      );
      this.auth.logout().subscribe(() => this.router.navigate(["/login"]));
    }
    this.autenticador = localStorage.getItem("handleBenef");
  }

  ngOnInit() {
    this.showProgressSpinner();

    this.userHandle = localStorage.getItem("handleBenef");


    this.beneficiarioService
      .consultaDadosBeneficiario(this.userHandle)
      .subscribe((x) => {
        this.beneficiarios$ = of(x);
        this.userHandle = String(x.id);
        this.pep = x.situacaoPep === true ? Alert.SUCCESS : Alert.WARNING;
        this.BeneficiarioSelecionado = of(x);
        this.beneficiarioCompleto = true;
        this.progressSpinnerDialogRef.close();
        EventEmitterService.get("ExamesPorUserHandleEmitter").emit(
          x.id.toString()
        );
      });
  }

  ngOnDestroy() {
    this.messageService.close();
  }

  abrirImprimirTermo() {
    return this.router.navigate(["/enviar-termo", this.userHandle]);
  }

  carregaBeneficiario(): Observable<DadosBeneficiario> {
    return this.beneficiarioService.consultaDadosBeneficiarioToken();
  }

  visualizarTermo() {
    this.termoAceite.visuTermoBeneficiario().subscribe((x) => {
      var blob = new Blob([x], { type: "application/pdf" });

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        var base64string = reader.result;
        this.base64 = base64string.toString();
        EventEmitterService.get("Base64TermoVisualizar").emit(this.base64);
      };
      this.router.navigate(["visualizar"]);
    });
  }

  selecionaExamesPorUserHandle(itemDependente: any) {
    if (itemDependente) {
      this.beneficiarios$.subscribe((x) => {
        this.BeneficiarioSelecionado =
          x.id == itemDependente
            ? this.beneficiarios$
            : of(x.dependentes.filter((y) => y.id == itemDependente)[0]);
      });
    }

    EventEmitterService.get("ExamesPorUserHandleEmitter").emit(itemDependente);
  }
}
