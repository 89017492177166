import { BeneficiarioService } from "./../services/beneficiario.service";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationEnd,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { take } from "rxjs/operators";
import { AuthenticationService } from "../services/authentication.service";
import { Beneficiario } from "../types/Beneficiario";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  sessionCookie: string;
  tokenPayload: {
    nameid: string;
    UserApelido: string;
    UserHandle: string;
    given_name: string;
    UserRole: string;
    exp: number;
    iat: number;
    nbf: number;
    role: string;
    unique_name: string;
  };
  beneficiario: Beneficiario;

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private cookieService: CookieService,
  ) {
    this.sessionCookie = this.cookieService.get("access_token");
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!!this.auth.currentUserValue) {
      try {
        this.tokenPayload =
          JSON.parse(atob(this.auth.currentUserValue.token.split(".")[1])) ||
          undefined;
      } catch (error) {
        this.logout();
      }
      if (
        route.data.roles &&
        route.data.roles.indexOf(this.auth.currentUserValue.tipoUsuarioId) ===
        -1
      ) {
        //orle not authorised so redirect to home page
        if (this.auth.currentUserValue.situacaoPep !== undefined) {
          if (this.auth.currentUserValue.tipoUsuarioId === 1) {
            if (
              !!this.auth.currentUserValue.alterarSenha &&
              this.router.url !== `/trocar-senha`
            ) {
              this.router.navigate(["/trocar-senha"]);
              return false;
            }


          }
          this.router.navigate(["/detalhes"]);
          return false;
        }
      }
      return true;
    }

    this.auth.logout().subscribe((x) => this.router.navigate(["/login"]));
    return false;
  }

  logout() {
    this.auth.logout().subscribe((x) => this.router.navigate(["/login"]));
  }
}
